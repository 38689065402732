import { useState } from 'react';

import NavBar from '../components/navbar.js';
import Footer from '../components/footer.js';

import PartyRoom from '../components/services/partyRoom.js';
import SaunaRoom from '../components/services/saunaRoom.js';
import Kitchen from '../components/services/kitchen.js';
import HomeAccomodation from '../components/services/homeAccomodation.js';
import Camping from '../components/services/camping.js';
import Stage from '../components/services/stage.js';
import Beach from '../components/services/beach.js';

import arrowRight from '../assets/media/arrowRight.png';
import arrowLeft from '../assets/media/arrowLeft.png';

function Services() {

  const [service, viewService] = useState('party')
  const [menuOpen, toggleMenu] = useState(false)

  return (
    <div className='mainContainer'>
      <NavBar />
      <div className='contentContainer'>
        {menuOpen &&
          <div className='mobileSideMenu'>
            <div onClick={() => {viewService('party'); toggleMenu(false)}} className='mobileSideMenu__item'>Peoruum</div>
            <div onClick={() => {viewService('sauna'); toggleMenu(false)}} className='mobileSideMenu__item'>Saunad</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Väliköök</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Kodumajutus</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Kämpingud</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Välilava</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item_last'>Rand</div>
          </div>
        }
        {!menuOpen &&
          <div onClick={() => toggleMenu(true)} className='mobileSideMenuButton'>
            <img src={arrowRight} className='sideMenuImage'/>
          </div>
        }
        <div className='servicesContainer'>
          <div className='left_menu'>
            <div onClick={() => viewService('party')} className='left_menu__item'>Peoruum</div>
            <div onClick={() => viewService('sauna')} className='left_menu__item'>Saunad</div>
            <div className='left_menu__item'>Väliköök</div>
            <div className='left_menu__item'>Kodumajutus</div>
            <div className='left_menu__item'>Kämpingud</div>
            <div className='left_menu__item'>Välilava</div>
            <div className='left_menu__item_last'>Rand</div>
          </div>
          <div className='services_content'>
            {service == 'party' && <PartyRoom /> }
            {service == 'sauna' && <SaunaRoom /> }
            {service == 'kitchen' && <PartyRoom /> }
            {service == 'home' && <PartyRoom /> }
            {service == 'capming' && <PartyRoom /> }
            {service == 'stage' && <PartyRoom /> }
            {service == 'beach' && <PartyRoom /> }

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Services;
