import firebase from "firebase";
const config = {
  apiKey: "AIzaSyAIqh-ATlRYIahxokhAKGife7Gocdf2TUY",
  authDomain: "sepakohvik-8f851.firebaseapp.com",
  projectId: "sepakohvik-8f851",
  storageBucket: "sepakohvik-8f851.appspot.com",
  messagingSenderId: "670373966972",
  appId: "1:670373966972:web:79486740a356ba7d8c05f9"
};
firebase.initializeApp(config);
export const db = firebase.database();
export const storage = firebase.storage();
export const storageRef = storage.ref();
