import { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/media/logo.png';
import facebook from '../assets/media/facebook.png';
import instagram from '../assets/media/instagram.png';
import openMenu from '../assets/media/openMenu.png';
import closeMenu from '../assets/media/closeMenu.png';

function NavBar() {

  const [menu, toggleMenu] = useState(false)

  return (
    <div className='navbar'>
      <div className='navbar__header'>
        <div className='socialFacebook'>
          <a href='https://www.facebook.com/aksirantso' target='_blank'>
            <div className='social__row'>
              <img src={facebook} className='social__img' />
              <text className='whiteBold'>@aksirantso</text>
            </div>
          </a>
        </div>
        <div className='socialInstagram'>
          <a href='https://www.instagram.com/aksimotorantso/' target='_blank'>
            <div className='social__row'>
              <img src={instagram} className='social__img' />
              <text className='whiteBold'>@aksimotorantso</text>
            </div>
          </a>
          <div>
            <img src={openMenu} className='mobileMenuButton'/>
          </div>
        </div>
        <div className='navbar__logo_container'>
          <img src={logo} className='navbar__logo'/>
        </div>
        <div>
          <img src={menu ? closeMenu : openMenu} onClick={() => menu ? toggleMenu(false) : toggleMenu(true)} className='mobileMenuButton'/>
        </div>
      </div>
      <div className='navbar__links'>
        <div className='navbar__links_left'>
          <Link to={{pathname: '/'}} className='navbar__links_link'>Avaleht</Link>
          <Link to={{pathname: '/services'}} className='navbar__links_link'>Teenused</Link>
          <Link to={{pathname: '/circuit'}} className='navbar__links_link'>Ringrada</Link>
          <Link to={{pathname: '/garage'}} className='navbar__links_link'>Garage</Link>
        </div>
        <div className='navbar__links_right'>
          <Link to={{pathname: '/events'}} className='navbar__links_link'>Üritused</Link>
          <Link to={{pathname: '/shop'}} className='navbar__links_link'>Müük</Link>
          <Link to={{pathname: '/reservations'}} className='navbar__links_link'>Broneerimine</Link>
          <Link to={{pathname: '/contact'}} className='navbar__links_link'>Kontakt</Link>
        </div>

      </div>
      {menu &&
      <div className='mobileLinksContainer'>
        <Link to={{pathname: '/'}}  className='mobileLink'>Avaleht</Link>
        <Link to={{pathname: '/services'}} className='mobileLink'>Teenused</Link>
        <Link to={{pathname: '/circuit'}} className='mobileLink'>Ringrada</Link>
        <Link to={{pathname: '/garage'}} className='mobileLink'>Garage</Link>
        <Link to={{pathname: '/events'}} className='mobileLink'>Üritused</Link>
        <Link to={{pathname: '/shop'}} className='mobileLink'>Müük</Link>
        <Link to={{pathname: '/reservations'}} className='mobileLink'>Broneerimine</Link>
        <Link to={{pathname: '/contact'}} className='mobileLink'>Kontakt</Link>
      </div>
      }
    </div>
  )
}

export default NavBar;
