import React, { Component } from 'react';

import dirtbike from '../../assets/media/rent/dirtbike.jpeg';
import e39 from '../../assets/media/rent/e39.jpg';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


class Rent extends Component {

  state = {
    isOpen: false,
    image: ''
  }


  render() {
    if(this.state.isOpen) {
      return (
        <Lightbox
          mainSrc={this.state.image}
          onCloseRequest={() => this.setState({ isOpen: false })}
        />
      )
    }

    return (
      <div className='services_content__continer'>
        <div className='services_content__text'>
          <h1>Tehnika rent</h1>
          <text>Rendi tingimused </text>
          <text>Rendi tigimuste tekst.</text>
        </div>
        <div className='newChapter'>Tehnika</div>
        <div className='rentalTable'>
          <div className='rentalTable__row'>
            <div className='rentalTable__left'>
              <div className='rentalTable__image'>
                <img src={e39} />
              </div>
              <div className='rentalTable__text'>
                <text className='rentalTable__heading'>E39 Winterbeater</text>
                <text className='rentalTable__description'>523i 125kw manuaal</text>
              </div>
            </div>
            <div className='rentalTable__price'>25€/päev</div>
          </div>
          <div className='rentalTable__row'>
            <div className='rentalTable__left'>
              <div className='rentalTable__image'>
                <img src={dirtbike} />
              </div>
              <div className='rentalTable__text'>
                <text className='rentalTable__heading'>Dirtbike</text>
                <text className='rentalTable__description'>kirjeldus</text>
              </div>
            </div>
            <div className='rentalTable__price'>15€/päev</div>
          </div>
        </div>

      </div>
    )
  }

}

export default Rent;
