import React, { Component } from 'react';

import dirt1 from '../../assets/media/dirt/dirt_1.jpg';
import dirt2 from '../../assets/media/dirt/dirt_2.jpg';
import dirt3 from '../../assets/media/dirt/dirt_3.jpg';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


class Dirt extends Component {

  state = {
    isOpen: false,
    image: ''
  }


  render() {
    if(this.state.isOpen) {
      return (
        <Lightbox
          mainSrc={this.state.image}
          onCloseRequest={() => this.setState({ isOpen: false })}
        />
      )
    }

    return (
      <div className='services_content__continer'>
        <div className='services_content__text'>
          <h1>Põllurada</h1>
          <text>Põllurada on mõeldud sõitmiseks tsiklitele, atv/quad ja autodega.</text>
          <text>Rajale võib tulla nii enda autoga, kui ka kohapealt võimalik rentida selleks ette nähtud autot koos kiivriga.</text>
          <text>Raja pikkus 1 km. Rajal sõitmine omal vastutsel, kohustustlik kiivri kasutus!</text>

          <div class='newChapter'>Raja tasu</div>
          <text>Päev: <text className='boldText'>10 €</text></text>

          <div class='newChapter'>Avatud</div>
          <text>E-P <text className='boldText'>10.00 - 21.00</text></text>
          <text>Soovituslik ette helistada alati +372 58373902</text>

        </div>
        <div className='bottomGallery_container' ref={this.gallery}>
          <img src={dirt1} onClick={() => this.setState({image: dirt1, isOpen: true})} className='bottomGallery__image'/>
          <img src={dirt2} onClick={() => this.setState({image: dirt2, isOpen: true})} className='bottomGallery__image'/>
          <img src={dirt3} onClick={() => this.setState({image: dirt3, isOpen: true})} className='bottomGallery__image'/>
        </div>
      </div>
    )
  }

}

export default Dirt;
