import { useState } from 'react';

import NavBar from '../components/navbar.js';
import Footer from '../components/footer.js';


function Contact() {

  return (
    <div className='mainContainer'>
      <NavBar />
      <div className='contactsContainer'>
        <div className='contactsContainer_text'>
          <div className='eventsContainer__text'>
            <h1>Äksi Motoklubi</h1>
          </div>
          <div className='newChapter'>Kontakt</div>
          <text>Telefon: <text className='boldText'>+372 58373902</text></text>
          <text>E-Post: <text className='boldText'>motorantso@gmail.com</text></text>

          <div className='newChapter'>Motorantso OÜ</div>
          <text>Reg.nr.: <text className='boldText'>14220923</text></text>
          <text>Aadress: <text className='boldText'>Tartumaa, Äksi küla, Äksi tee 38</text></text>


          <div className='newChapter'>Võistlused, personaalsed treeningud ning MTÜ Äksi Motoklubiga seonduvad küsimused:</div>
          <text className='boldText'>Meelis Pärtelpoeg</text>
          <text>+372 56693832</text>
          <text>aksi.mk@gmail.com</text>
          <text>MTÜ Äksi Motoklubi</text>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2082.8743701538706!2d26.647911215914046!3d58.53031098138641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eb4b2ef22b8f97%3A0x57e15110fd85d9ab!2s%C3%84ksi%20Motorantso%20%2F%20%C3%84ksi%20Moto%20Club!5e0!3m2!1sen!2see!4v1611842680486!5m2!1sen!2see" class='contactsContainer__map'></iframe>
      </div>
      <Footer />
    </div>
  )
}

export default Contact;
