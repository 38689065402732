import React, { Component } from 'react';

import room1 from '../../assets/media/partyRoom/peoruum_1.jpg';
import room2 from '../../assets/media/partyRoom/peoruum_2.jpg';
import room3 from '../../assets/media/partyRoom/peoruum_3.jpg';
import room4 from '../../assets/media/partyRoom/peoruum_4.jpg';
import room5 from '../../assets/media/partyRoom/peoruum_5.jpg';
import room6 from '../../assets/media/partyRoom/peoruum_6.jpg';
import room7 from '../../assets/media/partyRoom/peoruum_7.jpg';
import room8 from '../../assets/media/partyRoom/peoruum_8.jpg';
import room9 from '../../assets/media/partyRoom/peoruum_9.jpg';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


class PartyRoom extends Component {

  state = {
    isOpen: false,
    image: ''
  }


  render() {
    if(this.state.isOpen) {
      return (
        <Lightbox
          mainSrc={this.state.image}
          onCloseRequest={() => this.setState({ isOpen: false })}
        />
      )
    }

    return (
      <div className='services_content__container'>
        <div className='services_content__text'>
          <h1>Peoruum</h1>
          <text>Hubane rantšo stiilis peoruum.</text>
          <text>
            Peoruumi juurde kuuluvad:
            <text className='boldText'> helitehnika</text>,
            <text className='boldText'> peovalgustus</text>,
            <text className='boldText'> köök</text>,
            <text className='boldText'> baarilett</text>,
            <text className='boldText'> wc</text>,
            <text className='boldText'> dušš</text>,
            <text className='boldText'> piljard</text>.

          </text>
          <text>Istekohti kuni 55-le inimesele.</text>
          <text>Laudu võimalik paigutada vastavalt soovile, kas pikaks lauaks või eraldi 5-ks lauaks.</text>

          <div class='newChapter'>Rendiaeg</div>
          <text>Alates 17:00 kuni järgmise päeva 12:00 on rendihind <text className='boldText'>200€</text></text>
          <text>koos nelja magamistoaga hind kokku <text className='boldText'>250€</text></text>
          <text>Broneeringu tasu <text className='boldText'>100€</text> (võimalusel kogu summa)</text>

          <div class='newChapter'>Küsi pakkumist</div>
          <text><text className='boldText'>+372 58373902</text> või <text className='boldText'>motorantso@gmail.com</text></text>

          <div className='newChapter'>Catering</div>
          <text className='boldText'>Sõbra Pesa Catering</text>
          <text>sobrapesatartu@gmail.com</text>
          <text>+372 56963366</text>
          <text>http://www.sobrapesa.ee</text>
        </div>
        <div className='bottomGallery_container' ref={this.gallery}>
          <img src={room1} onClick={() => this.setState({image: room1, isOpen: true})} className='bottomGallery__image'/>
          <img src={room2} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
          <img src={room3} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
          <img src={room4} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
          <img src={room5} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
          <img src={room6} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
          <img src={room7} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
          <img src={room8} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
          <img src={room9} onClick={() => this.setState({image: room2, isOpen: true})} className='bottomGallery__image'/>
        </div>
      </div>
    )
  }

}

export default PartyRoom;
