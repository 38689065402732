import React, { Component } from 'react';

import bike1 from '../../assets/media/bikes/bike1.jpg';
import bike2 from '../../assets/media/bikes/bike2.jpg';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';


class Technics extends Component {

  state = {
    isOpen: false,
    image: ''
  }

  render() {
    if(this.state.isOpen) {
      return (
        <Lightbox
          mainSrc={this.state.image}
          onCloseRequest={() => this.setState({ isOpen: false })}
        />
      )
    }

    return (
      <div className='services_content__continer'>
        <div className='services_content__text'>
          <h1>Mootorrattad</h1>
          <text>Müügiinfo: <text className='boldText'>+372 58373902</text></text>
        </div>
        <div className='newChapter'>Tooted</div>
        <div className='rentalTable'>
          <div className='rentalTable__row'>
            <div className='rentalTable__left'>
              <div className='rentalTable__image'>
                <img src={bike1} className='rentalTable__image' onClick={() => this.setState({image: bike1, isOpen: true})}/>
              </div>
              <div className='rentalTable__text'>
                <text className='rentalTable__heading'>Yamaha YZ 450F</text>
                <text className='rentalTable__description'>2018 / 450cm3 / Äsja tehtud mootori remont ja amortide hooldus.</text>
              </div>
            </div>
            <div className='rentalTable__price'>4200 €</div>
          </div>
          <div className='rentalTable__row'>
            <div className='rentalTable__left'>
              <div onClick={() => this.setState({image: bike2, isOpen: true})}>
                <img src={bike2} className='rentalTable__image'/>
              </div>
              <div className='rentalTable__text'>
                <text className='rentalTable__heading'>KTM 350 SX-F</text>
                <text className='rentalTable__description'>2020 / 350cm3 / </text>
              </div>
            </div>
            <div className='rentalTable__price'>5500 €</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Technics;
