import React, { Component } from 'react';

class SaunaRoom extends Component {


  render() {

    return (
      <div className='services_content__container'>
        <div className='services_content__text'>
          <h1>Saunad</h1>
          <div class='newChapter'>Tavasaun</div>
          <text>Kohe peoruumi sissetuleku kõrval. Mahutab korraga lavale <text className='boldText'>kuni 7 inimest</text>.</text>
          <text>Esimene tund <text className='boldText'>20€</text></text>
          <text>Järgnev tund <text className='boldText'>12€</text></text>
          <text>Broneeringu tasu <text className='boldText'>100€</text> (võimalusel kogu summa)</text>

          <div class='newChapter'>Kilesaun</div>
          <text>Asub peoruumidest 30 meetri kaugusel metsa serval tiigi ääres. Mahutab korraga lavale <text className='boldText'>kuni 6 inimest</text>.</text>
          <text>Esimene tund <text className='boldText'>25€</text></text>
          <text>Järgnev tund <text className='boldText'>15€</text></text>
        </div>
      </div>
    )
  }

}

export default SaunaRoom;
