import team39 from '../assets/media/team39.jpg';
import aksel from '../assets/media/aksel.jpg';
import p2ts from '../assets/media/p2ts.jpg';

function Footer() {
  return (
    <div className='footer'>
      <div className='footerContacts'>
        <text className='whiteBold'>Äksi Motorantso</text>
        <text className='whiteBold'>Tartumaa, Äksi küla, Äksi tee 38</text>
        <text className='whiteBold'>+372 58373902</text>
      </div>
      <div className='footerPartners'>
        <a href='https://www.facebook.com/AksiMotoklubi' targer='_blank'>
          <div className='footerParnter'>
            <div className='footerPartner__imageContainer'>
              <img src={team39} className='footerParnter__image' />
            </div>
            <text className='footerParnter__text'>Äksi Team 39</text>
          </div>
        </a>
        <a href='https://www.facebook.com/Aksel-P%C3%A4rtelpoeg-39-178700656049125' targer='_blank'>
          <div className='footerParnter'>
            <div className='footerPartner__imageContainer'>
              <img src={aksel} className='footerParnter__image' />
            </div>
            <text className='footerParnter__text'>Aksel Pärtelpoeg #39</text>
          </div>
        </a>
        <a href='https://www.facebook.com/P%C3%A4ts-Garage-1812089079051784' targer='_blank'>
          <div className='footerParnter'>
            <div className='footerPartner__imageContainer'>
              <img src={p2ts} className='footerParnter__image' />
            </div>
            <text className='footerParnter__text'>Päts Garage</text>
          </div>
        </a>
      </div>
    </div>
  )
}

export default Footer;
