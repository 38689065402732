import { useState } from 'react';

import NavBar from '../components/navbar.js';
import Footer from '../components/footer.js';
import Technics from '../components/shop/technics.js';

function Shop() {

  const [service, viewService] = useState('tech')

  return (
    <div className='mainContainer'>
      <NavBar />
      <div className='contentContainer'>
        <div className='servicesContainer'>
          <div className='left_menu'>
            <div onClick={() => viewService('tech')} className='left_menu__item'>Mootorrattad</div>
            <div onClick={() => viewService('dirt')} className='left_menu__item'>Varustus</div>
          </div>
          <div className='services_content'>
            {service == 'tech' && <Technics /> }

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Shop;
