import React, { Component } from 'react';

import NavBar from '../components/navbar.js'
import Footer from '../components/footer.js'

import { db } from '../assets/services/firebase';
import homeGif from '../assets/media/home-gif.gif';
import mobile from '../assets/media/first_mobile.jpg';
import home from '../assets/media/home.png';



class Home extends Component {

  componentDidMount() {
    db.ref('data').set({
      lastSeen: new Date().toString()
    })
  }

  render() {
    return (
      <div className="mainContainer">
        <NavBar />
          <div className='homeContentContainer'>
            <img src={home} className='homeContainer__background'/>
            <div className='homeContainer'>
              <div className='homeContainer__text'>
                <h1>
                <text className='homeBigBold'>Äksi Motorantšo</text>
                </h1>
                <text className='whiteBold'>Pakume hubaseid rantšo stiilis ruume sünnipäevade, suvepäevade ja seminaride korraldamiseks Tartust vaid 15-minutilise sõidu kaugusel.
                Peosaalis on istekohti kuni 50-le inimesele ning laudu on võimalik paigutada vastavalt soovile, kas pikaks lauaks või eraldi 5-ks lauaks. Saalis on helitehnika, projektor, DJ pult, peovalgustus, köök, baarilett, piljard.
                Lisavõimalused: suur välilava, kämpingud, kodumajutus ja telkimine ning saunad. Lühikese jalutuskäigu kaugusele jääb Saadjärve rand koos privaatse ranna-ala, purde ning paadilaenutusega.
                Saadjärv asub 100 meetri kaugusel!</text>
              </div>
            </div>
          </div>
        <Footer />
      </div>
    )
  }

}

export default Home;
