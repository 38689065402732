import { useState } from 'react';

import NavBar from '../components/navbar.js';
import Footer from '../components/footer.js';

import Dirt from '../components/circuit/dirt.js';
import Rent from '../components/circuit/rent.js';

import arrowRight from '../assets/media/arrowRight.png';
import arrowLeft from '../assets/media/arrowLeft.png';

function Circuit() {

  const [service, viewService] = useState('dirt')
  const [menuOpen, toggleMenu] = useState(false)


  return (
    <div className='mainContainer'>
      <NavBar />
      <div className='contentContainer'>
        {menuOpen &&
          <div className='mobileSideMenu'>
            <div onClick={() => {viewService('dirt'); toggleMenu(false)}} className='mobileSideMenu__item'>Põllurada</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Krossirada</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Treeningud</div>
            <div onClick={() => {viewService('rent'); toggleMenu(false)}} className='mobileSideMenu__item'>Tehnika rent</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Tehnika hoiustamine</div>
          </div>
        }
        {!menuOpen &&
          <div onClick={() => toggleMenu(true)} className='mobileSideMenuButton'>
            <img src={arrowRight} className='sideMenuImage'/>
          </div>
        }
        <div className='servicesContainer'>
          <div className='left_menu'>
          <div onClick={() => viewService('dirt')} className='left_menu__item'>Põllurada</div>
          <div onClick={() => viewService('dirt')} className='left_menu__item'>Krossirada</div>
          <div className='left_menu__item'>Treeningud</div>
          <div onClick={() => viewService('rent')} className='left_menu__item'>Tehnika rent</div>
          <div className='left_menu__item'>Tehnika hoiustamine</div>
          </div>
          <div className='services_content'>
            {service == 'dirt' && <Dirt /> }
            {service == 'rent' && <Rent /> }

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Circuit;
