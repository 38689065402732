import { useState } from 'react';

import NavBar from '../components/navbar.js';
import Footer from '../components/footer.js';

import event1 from '../assets/media/events/event1.jpg';
import bronImage from '../assets/media/bronImage.jpg';


function Reservations() {

  const [service, viewService] = useState('dirt')

  return (
    <div className='mainContainer'>
      <NavBar />
      <div className='eventsContentContainer'>
        <div className='eventsContainer'>
          <div className='eventsContainer__text'>
            <h1>Broneerimine</h1>
          </div>
          <div className='eventsInformation'>
            <text>Broneerimiseks on kaks võimalust:</text>
            <text>Helistage numbril <text className='boldText'>+37258373902</text></text>
            <text>Või kirjutage aadressil <text className='boldText'>motorantso@gmail.com</text></text>
          </div>
          <div className='newChapter'>Broneerimistingimused</div>
          <text>- Alla 18. aastased saavad maja broneerida ja kasutada ainult koos täiskasvanuga.</text>
          <text>- Broneerigu ettemaks on 50%. Ülejäänud summa tuleb tasuda eelnevalt ülekandega või saabumisel sularahas.</text>
          <text>- Broneeringut on võimalik tühistada 7 päeva enne ürituse toimumist. Hilisemal tühistamisel ettemaksu ei tagastata.</text>
          <text>- Peomaja ning kämpingute rendiaeg on alates 17.00 kuni järgmine päev kell 12.00</text>

        </div>
        <div className='eventsImage_container'>
          <img src={bronImage} className='contactsContainer__map'/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Reservations;
