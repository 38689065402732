import { useState } from 'react';

import NavBar from '../components/navbar.js';
import Footer from '../components/footer.js';

import Service from '../components/garage/service.js';

import arrowRight from '../assets/media/arrowRight.png';
import arrowLeft from '../assets/media/arrowLeft.png';

function Garage() {

  const [service, viewService] = useState('service')
  const [menuOpen, toggleMenu] = useState(false)


  return (
    <div className='mainContainer'>
      <NavBar />

      <div className='contentContainer'>
        {menuOpen &&
          <div className='mobileSideMenu'>
            <div onClick={() => {viewService('service'); toggleMenu(false)}} className='mobileSideMenu__item'>Hooldus</div>
            <div onClick={() => {viewService('sauna'); toggleMenu(false)}} className='mobileSideMenu__item'>Varuosad</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Eriprojektid</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Taastamine</div>
            <div onClick={() => toggleMenu(false)} className='mobileSideMenu__item'>Meened ja karikad</div>
          </div>
        }
        {!menuOpen &&
          <div onClick={() => toggleMenu(true)} className='mobileSideMenuButton'>
            <img src={arrowRight} className='sideMenuImage'/>
          </div>
        }
        <div className='servicesContainer'>
          <div className='left_menu'>
            <div onClick={() => viewService('party')} className='left_menu__item'>Hooldus</div>
            <div onClick={() => viewService('sauna')} className='left_menu__item'>Varuosad</div>
            <div className='left_menu__item'>Eriprojektid</div>
            <div className='left_menu__item'>Taastamine</div>
            <div className='left_menu__item'>Meened ja karikad</div>
          </div>
          <div className='services_content'>
            {service == 'service' && <Service /> }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Garage;
