import React, { Component } from 'react';

class Service extends Component {

  render() {

    return (
      <div className='services_content__continer'>
        <div className='services_content__text'>
          <h1>Hooldus</h1>
          <text>Pakume  teenuseid mototehnikale-hooldus, transport, motohotell.</text>
          <div class='newChapter'>Lisainfo</div>
          <text>Karl 58373902 või garagepats@gmail.com</text>
        </div>
      </div>
    )
  }

}

export default Service;
