import { useState } from 'react';

import NavBar from '../components/navbar.js';
import Footer from '../components/footer.js';

import event1 from '../assets/media/events/event1.jpg';
import eventImage from '../assets/media/eventsImg.jpg';


function Events() {

  const [service, viewService] = useState('dirt')

  return (
    <div className='mainContainer'>
      <NavBar />
      <div className='eventsContentContainer'>
        <div className='eventsContainer'>
          <div className='eventsContainer__text'>
            <h1>Üritused motorantšos</h1>
          </div>
          <div className='eventsInformation'>
            <text>Motorantšos toimub ka avalike üritusi, kuhu on oodatud kõik külastajad.</text>
          </div>
          <div className='eventsTable'>
            <div className='eventsTable__row'>
              <div className='eventsTable__image_box'>
                <img src={event1} className='eventsTable__image'/>
              </div>
              <div className='eventTable__description'>
                <text className='blackBold'><text className='boldText'>25.07 </text>Hobby Cars Weekend Äksi 2020</text>
                <text>Hobiautode kokkutulek 2020.
Ajakava: 11:00-13:00 kogunemine Tartus Lõunakeskuse parklas ( kinnine parkla osa </text>
  <text className='boldText'>Loe edasi</text>
              </div>
            </div>
            <div className='eventsTable__row'>
              <div className='eventsTable__image_box'>
                <img src={event1} className='eventsTable__image'/>
              </div>
              <div className='eventTable__description'>
                <text className='blackBold'><text className='boldText'>25.07 </text>Hobby Cars Weekend Äksi 2020</text>
                <text>Hobiautode kokkutulek 2020.
Ajakava: 11:00-13:00 kogunemine Tartus Lõunakeskuse parklas ( kinnine parkla osa </text>
  <text className='boldText'>Loe edasi</text>
              </div>
            </div>
            <div className='eventsTable__row'>
              <div className='eventsTable__image_box'>
                <img src={event1} className='eventsTable__image'/>
              </div>
              <div className='eventTable__description'>
                <text className='blackBold'><text className='boldText'>25.07 </text>Hobby Cars Weekend Äksi 2020</text>
                <text>Hobiautode kokkutulek 2020.
Ajakava: 11:00-13:00 kogunemine Tartus Lõunakeskuse parklas ( kinnine parkla osa </text>
  <text className='boldText'>Loe edasi</text>
              </div>
            </div>
          </div>
        </div>
        <div className='eventsImage_container'>
          <img src={eventImage} className='eventImage'/>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Events;
