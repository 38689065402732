import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import './assets/css/style.css';

import Home from './pages/home.js';
import Services from './pages/services.js';
import Circuit from './pages/circuit.js';
import Garage from './pages/garage.js';
import Events from './pages/events.js';
import Shop from './pages/shop.js';
import Reservations from './pages/reservations.js';
import Contact from './pages/contact.js';

class App extends Component {
  render() {
    return (
        <Router>
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/services' component={Services} />
            <Route exact path='/circuit' component={Circuit} />
            <Route exact path='/garage' component={Garage} />
            <Route exact path='/events' component={Events} />
            <Route exact path='/shop' component={Shop} />
            <Route exact path='/reservations' component={Reservations} />
            <Route exact path='/contact' component={Contact} />
          </Switch>
        </Router>
    )
  }
}

export default App;
